import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { Button, DynamicBackButton, EmptyState, LoadingState, PageLoaderHeading, Pagination } from '@binhatch/ui';
import classNames from 'classnames';
import { ProductRequestStatus } from 'flexinet-api';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { productApi } from '@/integrations/api';
import { urls } from '@/utils/url';

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const config = { page };

export const LeadListPage: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [query, updateQuery] = useQueryParams({ config });

  const leads = useRemoteData({ key: `useLeads`, page: query.page }, async ({ page: nextToken }) =>
    productApi.listUserProductRequests(nextToken).then((r) => r.data)
  );

  return (
    <React.Fragment>
      <main className="space-y-2 xl:space-y-6">
        <div className="flex items-center justify-between gap-4">
          <div>
            <DynamicBackButton />

            <PageLoaderHeading loading={leads.isLoading || leads.isValidating}>
              <FormattedMessage id={translations.pages.leadList.title} />
            </PageLoaderHeading>
          </div>

          <Button
            appearance="primary"
            as={Link}
            className="flex h-10 items-center whitespace-nowrap px-4 lg:order-last"
            state={{ from: 1 }}
            to={urls.shop.leads.new}
          >
            <FormattedMessage id={translations.pages.leadList.create} />
          </Button>
        </div>

        <LoadingState loading={leads.isLoading || leads.isValidating}>
          {!leads.isLoading && !leads.data?.data.length && (
            <EmptyState>
              <FormattedMessage id={translations.pages.leadList.empty} />
            </EmptyState>
          )}

          <ul className="grid min-h-[7rem] gap-4">
            {leads.data?.data.map((lead) => (
              <li key={lead.id}>
                <div className="flex items-center justify-between gap-4 rounded bg-white p-8 shadow">
                  <div className="flex-1">
                    <div className="font-semibold">{lead.name}</div>
                    <div className="truncate whitespace-pre-line">{lead.description}</div>
                    <ul>
                      {lead.media.map((media, index) => (
                        <li key={index}>
                          <a href={media}>{media}</a>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="flex flex-col items-end gap-2">
                    <div
                      className={classNames('rounded px-2 py-1 text-sm', {
                        'bg-error text-error-light': true || lead.status === ProductRequestStatus.Accepted,
                        'bg-success text-green-900': lead.status === ProductRequestStatus.Rejected,
                        'bg-warning-light text-yellow-900': false && lead.status === ProductRequestStatus.Pending
                      })}
                    >
                      <FormattedMessage id={translations.enum.productRequestStatus[lead.status]} />
                    </div>

                    <FormattedDate dateStyle="long" timeStyle="short" value={lead.createdAt} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </LoadingState>

        <Pagination
          hasNext={!!leads.data?.nextToken}
          hasPrevious={!!leads.data?.prevToken}
          onNext={() => updateQuery({ page: leads.data?.nextToken })}
          onPrevious={() => updateQuery({ page: leads.data?.prevToken })}
        />
      </main>

      {children}
    </React.Fragment>
  );
};
