import { translations } from '@binhatch/locale';
import { UserRole } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, Outlet, Route, Routes } from 'react-router';

import { urls } from '@/utils/url';

import { Auth } from '@/containers/useAuth';
import { Cart } from '@/containers/useCart';
import { SideNavigation } from '@/containers/useSideNavigation';
import { Tenant } from '@/containers/useTenant';

import { Navigation } from '@/components/Navigation';

import { LoadingPage } from '@/pages/LoadingPage';
import { LoginPage } from '@/pages/LoginPage';
import { NotificationPage } from '@/pages/NotificationsPage';
import { CustomDealClaimListPage } from '@/pages/customDeals/CustomDealClaimListPage';
import { CustomDealDetailPage } from '@/pages/customDeals/CustomDealDetailPage';
import { CustomDealListPage } from '@/pages/customDeals/CustomDealListPage';
import { CustomDealProgressPage } from '@/pages/customDeals/CustomDealProgressPage';
import { LeadDetailPage } from '@/pages/leads/LeadDetailPage';
import { LeadListPage } from '@/pages/leads/LeadListPage';
import { OrderDetailPage } from '@/pages/orders/OrderDetailPage';
import { OrderListPage } from '@/pages/orders/OrderListPage';
import { ClientBalanceHistoryPage } from '@/pages/profile/ClientBalanceHistoryPage';
import { ProfilePage } from '@/pages/profile/ProfilePage';
import { UserBalanceHistoryPage } from '@/pages/profile/UserBalanceHistoryPage';
import { PromotionClaimListPage } from '@/pages/promotions/PromotionClaimListPage';
import { PromotionConfigurationPage } from '@/pages/promotions/PromotionConfigurationPage';
import { PromotionDetailPage } from '@/pages/promotions/PromotionDetailPage';
import { PromotionListPage } from '@/pages/promotions/PromotionListPage';
import { PromotionProgressPage } from '@/pages/promotions/PromotionProgressPage';
import { SettingsGeneralPage } from '@/pages/settings/SettingsGeneralPage';
import { SettingsNotificationsPage } from '@/pages/settings/SettingsNotificationsPage';
import { SettingsPage } from '@/pages/settings/SettingsPage';
import { CartPage } from '@/pages/shop/CartPage';
import { CartSlideOver } from '@/pages/shop/CartSlideOver';
import { ProductDetailPage } from '@/pages/shop/ProductDetailPage';
import { ShopPage } from '@/pages/shop/ShopPage';
import { UserDetailPage } from '@/pages/users/UserDetailPage';
import { UserListPage } from '@/pages/users/UserListPage';
import { UserOrderListPage } from '@/pages/users/UserOrderListPage';
import { UserTransactionListPage } from '@/pages/users/UserTransactionListPage';

export const App: React.FC = () => {
  const tenant = Tenant.useContainer();
  const { authenticated, loading, context } = Auth.useContainer();

  if (loading) return <LoadingPage />;

  if (!authenticated) return <LoginPage />;

  return (
    <Cart.Provider>
      <SideNavigation.Provider>
        <div className="flex flex-1 flex-col md:flex-row">
          <Navigation />

          <div className="flex flex-1 flex-col">
            {!!context?.client?.isSuspended && (
              <div className="bg-error p-4 text-sm font-semibold text-white">
                <FormattedMessage id={translations.banner.suspended} />
              </div>
            )}

            {!!tenant.data?.announcement && <div className="bg-warning-light p-4 text-sm font-semibold">{tenant.data?.announcement}</div>}

            <div className="flex flex-1 flex-col p-4 xl:px-12 xl:py-6">
              <Routes>
                <Route element={<ProfilePage />} path={urls.profile.root}>
                  {context?.user.role === UserRole.CustomerAdmin && <Route element={<ClientBalanceHistoryPage />} path={urls.profile.root} />}

                  <Route
                    element={<UserBalanceHistoryPage />}
                    path={context?.user.role === UserRole.CustomerAdmin ? urls.profile.transactions.user : urls.profile.root}
                  />
                </Route>

                <Route element={<PromotionListPage />} path={urls.promotions.root} />
                <Route element={<PromotionClaimListPage />} path={urls.promotions.claims} />
                <Route element={<PromotionDetailPage />}>
                  <Route element={<PromotionProgressPage />} path={urls.promotions.one} />
                  <Route element={<PromotionConfigurationPage />} path={urls.promotions.config} />
                </Route>

                <Route element={<CustomDealListPage />} path={urls.customDeals.root} />
                <Route element={<CustomDealClaimListPage />} path={urls.customDeals.claims} />
                <Route element={<CustomDealDetailPage />}>
                  <Route element={<CustomDealProgressPage />} path={urls.customDeals.one} />
                </Route>

                <Route
                  element={
                    <React.Fragment>
                      <Outlet />
                      <CartSlideOver />
                    </React.Fragment>
                  }
                >
                  <Route element={<ShopPage />} path={urls.shop.root} />
                  <Route element={<ProductDetailPage />} path={urls.shop.products.one} />
                  <Route element={<CartPage />} path={urls.shop.cart} />
                  <Route element={<LeadListPage />} path={urls.shop.leads.root} />
                  <Route element={<LeadDetailPage />} path={urls.shop.leads.new} />
                </Route>

                <Route element={<OrderListPage />} path={urls.orders.root} />
                <Route element={<OrderDetailPage />} path={urls.orders.one} />

                {context?.user.role === UserRole.CustomerAdmin && (
                  <React.Fragment>
                    <Route element={<UserListPage />} path={urls.users.root} />
                    <Route element={<UserDetailPage />} path={urls.users.one}>
                      <Route element={<UserOrderListPage />} path={urls.users.orders} />
                      <Route element={<UserTransactionListPage />} path={urls.users.transactions} />
                    </Route>
                  </React.Fragment>
                )}

                <Route element={<NotificationPage />} path={urls.notifications} />

                <Route element={<SettingsPage />} path={urls.settings.root}>
                  <Route element={<SettingsGeneralPage />} path={urls.settings.root} />
                  <Route element={<SettingsNotificationsPage />} path={urls.settings.notifications} />
                  <Route element={<Navigate to={urls.settings.notifications} />} path={urls.settings.root} />
                </Route>

                <Route element={<Navigate replace to={urls.promotions.root} />} path="*" />
              </Routes>
            </div>
          </div>
        </div>
      </SideNavigation.Provider>
    </Cart.Provider>
  );
};
