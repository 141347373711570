import { useRemoteData } from '@binhatch/hooks';
import { getAllFromApi } from '@binhatch/utility';
import { ProgressStateAggregation, Promotion, UserPromotion } from 'flexinet-api';
import React from 'react';

import { progressApi } from '@/integrations/api';

export const useClaimablePromotionPeriod = (promotions: (Promotion | UserPromotion)[]) => {
  const [claimed, setClaimed] = React.useState<string[]>([]);

  const { data: progress } = useRemoteData({ key: `useClaimablePromotionProgress`, promotions }, async ({ promotions }) => {
    if (!promotions.length) return;

    return await getAllFromApi(
      (nextToken) =>
        progressApi
          .listUserProgress(
            promotions.map((p) => p.id),
            undefined,
            nextToken,
            ProgressStateAggregation.Confirmed
          )
          .then((r) => r.data),
      (r) => r.data
    );
  });

  return React.useMemo(() => {
    const now = Date.now();

    const claimable = new Map(
      promotions.map((promotion) => {
        const periods = new Map(promotion.periods?.filter((p) => new Date(p.endAt).getTime() <= now).map((p) => [p.id, p]));

        const claimableProgresses =
          progress
            ?.filter((p) => p.promotionId === promotion.id)
            .filter((p) => periods.has(p.periodID))
            .filter((p) => parseFloat(p.value) >= parseFloat(promotion.target.levels[0].value)) ?? [];

        return [
          promotion.id,
          claimed.includes(promotion.id)
            ? {}
            : claimableProgresses.length
            ? {
                period: claimableProgresses
                  .filter((p) => !p.claimedAt)
                  .map((p) => periods.get(p.periodID)!)
                  .sort((a, b) => a.startAt.localeCompare(b.startAt))[0]
              }
            : undefined
        ];
      })
    );

    return { claimable, markAsClaimed: (promotionId: string) => setClaimed((ids) => ids.concat(promotionId)) };
  }, [claimed, progress, promotions]);
};
