import { translations } from '@binhatch/locale';
import { CoinValue, UserAvatar } from '@binhatch/ui';
import { getUserName } from '@binhatch/utility';
import { Balance, BeneficiaryKind, User } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  user?: User;
  balance?: Balance;
}

export const ClientUserItem: React.FC<Props> = ({ user, balance }) => (
  <div className="flex flex-col items-center justify-between gap-4 rounded bg-white p-8 shadow md:flex-row">
    <div className="flex items-center gap-4">
      <UserAvatar className="flex-shrink-0" />

      <div className="flex-1">
        <div className="truncate font-semibold">{user ? getUserName(user) || user.details.email : '???'}</div>
        <div className="truncate">{!!user?.details && <FormattedMessage id={translations.enum.userRole[user.role]} />}</div>
      </div>
    </div>

    <div className="flex flex-col items-end">
      <div>
        <FormattedMessage id={translations.enum.balance[BeneficiaryKind.User]} />
      </div>

      <CoinValue value={balance?.amount ?? 0} />
    </div>
  </div>
);
