import { useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  AsyncButton,
  BonusValue,
  Card,
  DynamicBackButton,
  PageHeading,
  PromotionArticles,
  PromotionClaimed,
  PromotionImage,
  PromotionItemPeriod,
  Tab,
  TabList,
  TargetValue
} from '@binhatch/ui';
import { fromPromotion, getCurrency, getCurrentPeriod, getFallbackPeriod, getPromotionDays } from '@binhatch/utility';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router';

import { productApi, promotionApi } from '@/integrations/api';
import { fireConfetti } from '@/utils/confetti';
import { urls } from '@/utils/url';

import { RealtimeToggle } from '@/components/RealtimeToggle';
import { Auth } from '@/containers/useAuth';
import { useClaimablePromotionPeriod } from '@/hooks/useClaimablePromotionPeriod';

import { PeriodExpiration } from './components/PeriodExpiration';

export const PromotionDetailPage: React.FC = () => {
  const { context } = Auth.useContainer();
  const { promotionId } = useStrictParams<{ promotionId: string }>();

  const promotion = useRemoteData({ key: 'usePromotion', promotionId }, async ({ promotionId }) => {
    return promotionApi
      .getUserPromotion(promotionId)
      .then((r) => r.data)
      .then(fromPromotion);
  });

  const period = React.useMemo(() => {
    if (!promotion.data) return;

    return getCurrentPeriod(promotion.data.periods ?? []) ?? getFallbackPeriod(promotion.data.periods ?? []);
  }, [promotion.data]);

  const { claimable, markAsClaimed } = useClaimablePromotionPeriod(promotion.data ? [promotion.data] : []);

  return (
    <main className="space-y-2 md:space-y-6">
      <div className="flex justify-between gap-4">
        <DynamicBackButton />

        <RealtimeToggle />
      </div>

      <Card className="flex flex-col gap-4 xl:flex-row">
        <div className="flex flex-1 flex-wrap gap-4 md:flex-nowrap md:gap-8">
          <PromotionImage className="w-24" image={promotion.data?.imageURL} logo={promotion.data?.brandLogoURL} />

          <div className="flex w-full flex-col gap-2 md:gap-4">
            {promotion.data && <PromotionItemPeriod days={period ? getPromotionDays(period) : 0} promotion={promotion.data} {...{ period }} />}

            <PageHeading title={promotion.data?.name} />

            <PromotionArticles tags={promotion.data?.tags ?? {}} />

            <div>{promotion.data?.description}</div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          {!!promotion.data && (
            <div className="bg-shade-light grid gap-2 whitespace-nowrap rounded p-4">
              {promotion.data?.target.levels.map((level, index) => (
                <div className="flex items-center gap-4" key={index}>
                  <div className="font-semibold">
                    <FormattedMessage id={translations.pages.promotionCreate.targetLevelName} values={{ index: index + 1 }} />
                  </div>
                  <div className="flex items-center gap-2">
                    <TargetValue currency={getCurrency(promotion.data)} unit={promotion.data!.target.targetMu} value={parseFloat(level.value)} />
                    <span> = </span>
                    <BonusValue
                      getProduct={(id) => productApi.getUserProductById(id).then((r) => r.data)}
                      reference={level.bonus.ref}
                      unit={level.bonus.mu}
                      value={level.bonus.value}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          {!!claimable.get(promotionId) && (
            <div className="flex h-10 items-center justify-end">
              {!claimable.get(promotionId)?.period ? (
                <PromotionClaimed />
              ) : (
                <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
                  <PeriodExpiration period={claimable.get(promotionId)!.period!} />

                  <AsyncButton
                    appearance="primary"
                    className="h-10 px-4"
                    disabled={context?.client?.isSuspended}
                    onClick={async (event: MouseEvent) => {
                      await promotionApi.claimPromotionUserAll(promotionId);
                      markAsClaimed(promotionId);
                      fireConfetti(event.clientX, event.clientY);
                    }}
                  >
                    <FormattedMessage id={translations.pages.promotionClaimList.claim} />
                  </AsyncButton>
                </div>
              )}
            </div>
          )}
        </div>
      </Card>

      <TabList>
        <Tab end to={urls.promotions.getOne({ promotionId })}>
          <FormattedMessage id={translations.tabs.promotionProgress} />
        </Tab>

        {!!promotion.data?.ruleGroups?.length && (
          <Tab to={urls.promotions.getOne({ promotionId }, urls.promotions.config)}>
            <FormattedMessage id={translations.tabs.promotionConfig} />
          </Tab>
        )}
      </TabList>

      {!!promotion.data && <Outlet context={promotion.data} />}
    </main>
  );
};
