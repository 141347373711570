import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { Button, Card, EmptyState, LoadingState, NotificationIndicator, PageLoaderHeading, Pagination } from '@binhatch/ui';
import { BellIcon, Cog8ToothIcon } from '@heroicons/react/24/outline';
import { NotificationStatus } from 'flexinet-api';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { mutate } from 'swr';

import { notificationApi } from '@/integrations/api';
import { urls } from '@/utils/url';

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const config = { page };

export const NotificationPage: React.FC = () => {
  const [query, updateQuery] = useQueryParams({ config });

  const notifications = useRemoteData({ key: 'useNotifications', nextToken: query.page }, ({ nextToken }) =>
    notificationApi.listNotifications(nextToken).then((r) => r.data)
  );

  const markAsRead = React.useCallback(async () => {
    const notificationIds = notifications.data?.notifications.filter((n) => n.status === NotificationStatus.Unread).map((n) => n.id) ?? [];

    if (!notificationIds.length) return;

    await notificationApi.bulkMarkNotificationAsRead({ notificationIds });
    await notifications.mutate();
    await mutate({ key: 'useNotificationCount' });
  }, [notifications]);

  React.useEffect(() => {
    const timeout = setTimeout(markAsRead, 5000);

    return () => clearTimeout(timeout);
  }, [markAsRead]);

  return (
    <main className="space-y-2 xl:space-y-6">
      <div className="flex items-center justify-between gap-4">
        <PageLoaderHeading loading={notifications.isLoading || notifications.isValidating}>
          <FormattedMessage id={translations.pages.notificationList.title} />
        </PageLoaderHeading>

        <div>
          <Button appearance="secondary" as={Link} className="h-10 w-10" to={urls.settings.notifications}>
            <Cog8ToothIcon className="h-6 w-6" />
          </Button>
        </div>
      </div>

      <LoadingState loading={notifications.isLoading || notifications.isValidating}>
        <ul className="grid gap-4">
          {!notifications.isLoading && !notifications.data?.notifications.length && (
            <li>
              <Card>
                <EmptyState>
                  <FormattedMessage id={translations.pages.notificationList.empty} />
                </EmptyState>
              </Card>
            </li>
          )}

          {notifications.data?.notifications.map((notification) => (
            <li key={notification.id}>
              <Card className="flex flex-wrap gap-4">
                <div className="bg-shade-light relative h-10 w-10 rounded p-2">
                  <BellIcon className="h-6 w-6" />

                  {notification.status === NotificationStatus.Unread && (
                    <NotificationIndicator className="absolute right-0 top-0 -translate-y-1/2 translate-x-1/2" />
                  )}
                </div>

                <div className="flex-1 text-right leading-10 lg:order-last">
                  <FormattedDate dateStyle="long" timeStyle="short" value={notification.createdAt} />
                </div>

                <div className="w-full lg:w-auto lg:flex-1">
                  <div className="font-semibold">{notification.title}</div>
                  <div>{notification.body}</div>
                </div>
              </Card>
            </li>
          ))}
        </ul>
      </LoadingState>

      <Pagination
        hasNext={!!notifications.data?.nextToken}
        hasPrevious={!!notifications.data?.prevToken}
        onNext={() => {
          markAsRead().catch(() => void 0);
          updateQuery({ page: notifications.data?.nextToken });
        }}
        onPrevious={() => updateQuery({ page: notifications.data?.prevToken })}
      />
    </main>
  );
};
