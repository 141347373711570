import confetti from 'canvas-confetti';

const fire = (ratio: number, opts: confetti.Options) => {
  const count = 200;

  confetti({
    ...opts,
    disableForReducedMotion: true,
    particleCount: Math.floor(count * ratio)
  });
};

const distributeConfetti = (origin: confetti.Origin) => {
  fire(0.25, { origin, spread: 26, startVelocity: 55 });
  fire(0.2, { origin, spread: 60 });
  fire(0.35, { origin, spread: 100, decay: 0.91, scalar: 0.8 });
  fire(0.1, { origin, spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 });
  fire(0.1, { origin, spread: 120, startVelocity: 45 });
};

export const fireConfetti = (x: number, y: number) => {
  const origin = { x: x / window.innerWidth, y: y / window.innerHeight };

  distributeConfetti(origin);
  setTimeout(distributeConfetti, 200, origin);
  setTimeout(distributeConfetti, 400, origin);
};
